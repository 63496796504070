/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('bootstrap');
// window.Vue = require('vue').default;

window.Functions = require("./functions.js"); // To make the file's functions available in html files as a module
window.PDFJSExpress = require('@pdftron/pdfjs-express-viewer');




/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/* const app = new Vue({
    el: '#app',
}); */




/**
 * Pusher
 */
import Echo from 'laravel-echo';
import { updateCsrfToken } from './functions.js';

window.Pusher = require('pusher-js');
window.Pusher.logToConsole = false;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true,
    disableStats: true,
});


// Listen for the CsrfTokenUpdated event
const currentCsrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

window.Echo.channel('csrf-token.'+currentCsrfToken)
.listen('CsrfTokenUpdated', (data) => {
    console.log('csrf-token changed')

    // Update the CSRF token in this tab
    updateCsrfToken(data.newCsrfToken);
});
